import React, { useEffect } from 'react';
import qs from 'qs';
import { navigate } from 'gatsby';
import { auth } from '../firebase/config';

const VerifyEmail = (props) => {
  useEffect(() => {
    const { location } = props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log('query', query)
    if (query.mode === "verifyEmail" && query.oobCode) {
      return auth.applyActionCode(query.oobCode).then(() => window.location.replace(query.continueUrl))
        .catch(() => navigate(`/`))
    } else {
      return navigate(`/`);
    }
  }, [])

  return <div />
}

export default VerifyEmail 